(function() {
  function vendorModule() {
    'use strict';
    /* globals define, self */

    var api = function(credentials) {
      var s3 = new self.AWS.S3(credentials);

      this.upload = function(params, options, callback) {
        return s3.upload(params, options, callback);
      };
    };

    return { 'default': api };
  }

  define('aws-s3', [], vendorModule);
})();
